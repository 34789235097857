import newRequest from "../utils/newRequest";

export function getShopList(data) {
  return newRequest({
    url: "/product/shop/queryShopList/v287",
    method: "post",
    data
  });
}

export function issuedShopData(data) {
  return newRequest({
    url: "/product/shopSync/issue",
    method: "post",
    data
  });
}

export function issuedGoodsData(data) {
  return newRequest({
    url: "/product/spuSync/issue/by/goods",
    method: "post",
    data
  });
}

export function issuedShopProgress(key) {
  return newRequest({
    url: `/product/shopSync/queryProgress/${key}/v287`,
    method: "get"
  });
}

export function queryGoodsList(data) {
  return newRequest({
    url: "/product/yo/spu/list/v280",
    method: "post",
    data
  });
}
