<template>
  <base1-dialog
    ref="dialogRef"
    title="下发数据"
    width="700px"
    @closed="
      () => {$emit('closed')}
    "
  >
    <div style="padding-bottom: 40px">
      <div class="steps">
        <el-steps :active="active" align-center finish-status="success">
          <el-step title="统计数据" />
          <el-step title="下发数据" />
          <el-step title="下发完成" />
        </el-steps>
      </div>
      <!--      <div v-if="active === 0">-->
      <!--        <div class="flex-cc steps">-->
      <!--          <el-button class="fileBtn" @click="handleUpload">-->
      <!--            上传文件-->
      <!--            <input-->
      <!--              ref="excel-upload-input"-->
      <!--              class="excel-upload-input"-->
      <!--              type="file"-->
      <!--              accept=".xlsx, .xls"-->
      <!--              @change="handleClick"-->
      <!--            >-->
      <!--          </el-button>-->
      <!--        </div>-->
      <!--        <div class="Tips">-->
      <!--          <slot />-->
      <!--        </div>-->
      <!--      </div>-->
      <div v-if="active === 2 || active === 1">
        <div class="flex-cc steps">
          <el-progress v-loading="active === 1" type="circle" :percentage="percentage" />
        </div>
        <div class="text" v-if="active === 1">
          正在统计数据,请耐心等待...！
        </div>
        <div class="text" v-else>
          正在下发,数据有点多哦,请耐心等待...！
        </div>
      </div>
      <div v-if="active === 3">
        <div class="flex-cc icons steps">
          <i class="el-icon-circle-check" />
        </div>
        <div class="flex-cc">
          成功 {{ successNum }}条，失败{{ failNum }}条。
        </div>
      </div>

      <!--      // 样式二-->
      <!--      <div v-if="!showProgress">-->
      <!--        <input-->
      <!--          ref="excel-upload-input"-->
      <!--          class="excel-upload-input"-->
      <!--          type="file"-->
      <!--          accept=".xlsx, .xls"-->
      <!--          @change="handleClick"-->
      <!--        >-->
      <!--        <div class="drop" @drop="handleDrop" @dragover="handleDragover" @dragenter="handleDragover">-->
      <!--          {{ this.loading ? '解析中' : '将excel拖动到此处或点击' }}-->
      <!--          <el-button :loading="loading" style="margin-left:16px;" size="mini" type="primary" @click="handleUpload">-->
      <!--            选择-->
      <!--          </el-button>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div v-else style="width:100%; display: flex;align-items: center;justify-content: center;">-->
      <!--        <el-progress type="circle" :percentage="percentage" status="success"></el-progress>-->
      <!--      </div>-->
    </div>
  </base1-dialog>
</template>

<script>
import Base1Dialog from "components/BaseDialog/Base1Dialog.vue";

export default {
  name: "IssuedProgress",
  components: { Base1Dialog },
  props: {
    percentage: {
      type: Number,
      default: 0
    },
    active: {
      type: Number,
      default: 0
    },
    successNum: {
      type: Number,
      default: 0
    },
    failNum: {
      type: Number,
      default: 0
    }
  },
  methods: {
    open: function() {
      // this.successNum = 0;
      // this.failNum = 0;
      // this.percentage = 0;
      this.$refs.dialogRef.open();
    },
    close: function() {
      this.$refs.dialogRef.close();
    }
  }
};
</script>

<style scoped>
.flex-cc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.excel-upload-input {
  display: none;
  z-index: -9999;
}

.drop {
  border: 2px dashed #bbb;
  width: 100%;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}
</style>

<style lang="less" scoped>
.fileBtn {
  width: 290px;
  height: 40px;
  background: #3f81f0;
  border-radius: 4px;
  padding: 0;
  line-height: 40px;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 1rem;

  input {
    display: block;
    opacity: 0;
    cursor: pointer;
  }
}

.downloadData {
  color: #3f81f0;
}

.steps {
  padding-top: 20px;
  margin-bottom: 30px;
}

.text {
  text-align: center;
  font-size: 14px;
  color: #ce2323;
}

.icons {
  font-size: 80px;
  color: aquamarine;
}

.Tips {
  padding: 0 50px;
}

::v-deep.steps .is-process .el-step__icon.is-text {
  background: #3f81f0;
  color: #fff;
  border: 2px solid #3f81f0;
}

::v-deep.steps .is-wait .el-step__icon.is-text {
  background: #d8d8d7;
  color: #fff;
  border: 2px solid #d8d8d7;
}

//::v-deep .el-dialog__header {
//  text-align: left;
//  border-bottom: 1px solid #ebebeb;
//
//  .el-dialog__title {
//    font-size: 16px;
//    font-weight: bold;
//    color: #333333;
//  }
//}
</style>
