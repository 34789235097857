<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="width"
    :before-close="handleClose"
    class="base1_dialog"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    @closed="$emit('closed')"
  >
    <slot slot="title" name="title" />
    <div class="base1_dialog_spec" />
    <div class="base1_dialog_container" style="max-height: calc(100vh - 100px);overflow: auto">
      <slot />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "Base1Dialog",
  props: {
    width: {
      default: "90vw"
    },
    title: {
      default: ""
    },
    closeCallback: {
      type: Function,
      default: function() {
        return () => {
          return true;
        };
      }
    }
  },
  data: function() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    handleClose(done) {
      if (this.closeCallback(done)) {
        done();
      }
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done()
      //   })
      //   .catch(_ => {
      //   })
    },
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="less" scoped>

::v-deep .el-dialog {
  top: 50%;
  transform: translatey(-50%);
  margin-top: 0 !important;
  z-index: 10000;
}

::v-deep .el-dialog__header {
  padding: 22px 0 0 1.6vw;
}

::v-deep .el-dialog__headerbtn {
  right: 1.6vw;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
}

::v-deep .el-dialog__body {
  padding: 0;
}

.base1 {
  &_dialog {
    //z-index: 10000 !important;

    &_spec {
      margin: 17px 1.6vw 15px 1.6vw;
      border-top: 1px solid #EBEBEB;
    }

    &_container {
      //width: 100%;
      padding: 0 1.6vw;
    }
  }
}
</style>
